import { useState, useEffect, useContext } from 'react'
import { MagnifyingGlassIcon, CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline'

import * as api from 'util/api'
import InputWithButton from 'components/ui/InputWithButton'
import { Button } from 'components/gsys-ui'
import EnquiryCard from 'components/EnquiryColumn/EnquiryCard'
import usePaginated from 'hooks/usePaginated'
import NiceModal from '@ebay/nice-modal-react'
import { EnquiriesContext } from 'context/EnquiriesContext'
import { useEnquiries } from 'util/queries'
import moment from 'moment'
import Spinner from '../ui/Spinner'

const EnquiryColumn = () => {
  const { isLoading, isError, data: enqs, error } = useEnquiries()
  const [searchValue, setSearchValue] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  if (isError) return <div className="col-span-4">{JSON.stringify(error)}</div>

  const enqsSearched = searchValue ? (
    enqs.filter((enq) => {
      const str = `${enq.Reg.toLowerCase()}) ${enq.Status.toLowerCase()}) ${enq.Parts.toLowerCase()} ${enq.Notes.toLowerCase()}`
      const query = searchValue.toLowerCase()
      return str.includes(query)
    })
  ) : enqs

  const enqsFilteredStart = startDate ? (
    enqsSearched.filter((enq) => {
      return moment(enq.Date).isAfter(startDate)
    })
  ) : enqsSearched

  const enqsFilteredEnd = endDate ? (
    enqsFilteredStart.filter((enq) => {
      return moment(endDate).isAfter(enq.Date)
    })
  ) : enqsFilteredStart

  return (
    <div className="flex overflow-hidden flex-col col-span-4 h-full">
      <div className="flex mb-3 flex-0">
        <Button onClick={() => NiceModal.show('newEnquiryModal')}>
          <PlusIcon className="mr-2 w-5 h-5" />
          New Enquiry
        </Button>
      </div>
      <div className="flex mb-5 space-x-2 flex-0">
        <InputWithButton
          placeholder="Search..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        >
          <MagnifyingGlassIcon className="w-5 h-5" />
        </InputWithButton>
        <Button onClick={() => NiceModal.show('dateFilterModal', { startDate, endDate, setStartDate, setEndDate })}>
          <CalendarDaysIcon className="w-5 h-5" />
        </Button>
      </div>

      {
        startDate && endDate ? (
          <div className="mb-2 italic text-center text-gray-500">Showing enquiries between {moment(startDate).format('DD/MM/YY')} and {moment(endDate).format('DD/MM/YY')}</div>
        ) : !startDate && endDate ? (
          <div className="mb-2 italic text-center text-gray-500">Showing enquiries before {moment(endDate).format('DD/MM/YY')}</div>
        ) : startDate && !endDate ? (
          <div className="mb-2 italic text-center text-gray-500">Showing enquiries after {moment(startDate).format('DD/MM/YY')}</div>
        ) : null
      }

      {
        isLoading ? (
          <div className="inline-block mx-auto"><Spinner /></div>
        ) : (
          <div className="overflow-y-scroll flex-1 space-y-2">
            {
              enqsFilteredEnd.map((enq) => {
                //const eta = enqEtaCheck && enqEtaCheck[enq.EnquiryId] ? enqEtaCheck[enq.EnquiryId].Eta : undefined
                return <EnquiryCard key={enq.EnquiryId} enq={enq} />
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default EnquiryColumn