import * as Yup from 'yup'

export const enquiryValidation = Yup.object({
  Consumables: Yup.mixed()
    .oneOf(['Parts', 'Consumables'])
    .default('Parts'),
  Reg: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .min(3, 'Must be at least 3 characters')
    .matches(/^[a-zA-Z0-9 ]+$/)
    .required('Please enter a reg no')
    .default(''),
  Parts: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .default(''),
  Notes: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .default(''),
  Status: Yup.mixed()
    .oneOf(['Order', 'Waiting'])
    .default('Waiting'),
  Image: Yup.string()
    .nullable()
    .default(null)
})

export const userValidation = Yup.object({
  email: Yup.string()
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email ust be less than 254 characters')
    .email('Invalid email address')
    .required('Please enter an email address')
    .default(''),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(64, 'Password must be less than 64 characters')
    .matches(/[a-zA-Z0-9\.!\?\[\]\(\)_@#$&]/, 'Password can only contain letters, numbers and !.?[]()_@#$&')
    .required('Please enter a password')
    .default('')
})

export const clientValidation = Yup.object({
  userId: Yup.number()
    .integer()
    .required('Please provide a user ID'),
  name: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a first name')
    .default(''),
  addr1: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter an address')
    .default(''),
  addr2: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable()
    .default(null),
  city: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a city or town')
    .default(''),
  county: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable()
    .default(null),
  pcode: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a postcode')
    .default(''),
  tel: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a phone number')
    .default(''),
  email: Yup.string()
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email ust be less than 254 characters')
    .email('Invalid email address')
    .required('Please enter an email address')
    .default(''),
})

export const jobValidation = Yup.object({
  id: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a job ID')
    .default(''),
  clientId: Yup.number()
    .integer()
    .required('Please provide a client ID'),
  userId: Yup.number()
    .integer()
    .required('Please provide a user ID'),
  regNo: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .min(3, 'Must be at least 3 characters')
    .matches(/^[a-zA-Z0-9 ]+$/)
    .required('Please enter a reg no')
    .default(''),
  hasCarCover: Yup.boolean()
    .required()
    .default(false),
  isKeyFobWritten: Yup.boolean()
    .required()
    .default(false),
  hasMot: Yup.boolean()
    .required()
    .default(false),
  isTaxed: Yup.boolean()
    .required()
    .default(false),
  mileageIn: Yup.number()
    .integer()
    .required('Please enter the mileage')
    .default(0),
  mileageOut: Yup.number()
    .integer()
    .nullable()
    .default(null),
  status: Yup.mixed()
    .oneOf(['inProgress', 'pending', 'saved'])
    .default('inProgress')
})

export const postcodeValidation = Yup.object({
  lookup: Yup.string()
    .required('Please enter a postcode')
    .matches(/^[\w\ ]+$/)
    .default('')
})

export const partsListHeadValidation = Yup.object({
  id: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a list ID')
    .default(''),
  status: Yup.mixed()
    .oneOf(['inProgress', 'pending', 'saved'])
    .default('inProgress'),
  jobId: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .required('Please enter a job ID')
    .default(''),
  userId: Yup.number()
    .integer()
    .required('Please provide a user ID')
})

export const partsListItemValidation = Yup.object({
  qty: Yup.number()
    .integer()
    .required('Please provide a qty'),
  partId: Yup.string()
    .required('Please provide a part code')
})

export const tokenValidation = Yup.object({
  name: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .min(3, 'Must be at least 3 characters')
    .matches(/^[a-zA-Z0-9 ]+$/)
    .required('Please enter a name')
    .default('')
})