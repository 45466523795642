import { ClockIcon, ShoppingCartIcon, HandThumbUpIcon, ArchiveBoxIcon, CheckCircleIcon, TagIcon, NoSymbolIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-hot-toast';

// https://stackoverflow.com/a/18650249
export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const objectToFormData = (obj) => {
  let formData = new FormData()

  for (const key in obj) {
    formData.append(key, obj[key])
  }

  return formData
}

export const objectToUrlEncoded = (obj) => {
  let body = []

  for (const key in obj) {
    var encodedKey = encodeURIComponent(key)
    var encodedValue = encodeURIComponent(obj[key])
    body.push(encodedKey + '=' + encodedValue)
  }

  const ret = body.join('&')
  return ret
}

export const getEnqColour = (stStatus) => {
  if (
    stStatus == 'Waiting' ||
    stStatus == 'Order' ||
    stStatus == 'Garage Confirmed' ||
    stStatus == 'Archived'
  ) {
    return 'orange';
  } else if (stStatus == 'Ordered') {
    return 'green';
  } else if (stStatus == 'Quoted') {
    return 'blue';
  } else if (stStatus == 'Cancelled') {
    return 'red';
  }
}

export const getEnqIcon = (status) => {
  switch (status) {
    case 'Waiting':
      return ClockIcon
    case 'Order':
      return ShoppingCartIcon
    case 'Garage Confirmed':
      return HandThumbUpIcon
    case 'Archived':
      return ArchiveBoxIcon
    case 'Ordered':
      return CheckCircleIcon
    case 'Quoted':
      return TagIcon
    case 'Cancelled':
      return NoSymbolIcon
    default:
      return CheckCircleIcon
  }
}

export const successToast = (msg) => toast.success(
  msg,
  {
    position: 'bottom-center',
    style: {
      borderRadius: '6px',
      background: '#333',
      color: '#fff',
    }
  }
)

export const warningToast = (msg) => toast(
  msg,
  {
    icon: '⚠️',
    position: 'bottom-center',
    style: {
      borderRadius: '6px',
      background: '#333',
      color: '#fff',
    }
  }
)
