import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Checkbox = ({ checked, onClick }) => {
  return (
    <div
      className="flex justify-center items-center w-8 h-8 bg-white rounded border-2 border-gray-400 cursor-pointer"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCheck}
        className={clsx(
          "w-6 h-6 text-white rounded-sm transition-colors",
          { '!text-blue-500': checked }
        )} />
    </div>
  )
}

export default Checkbox