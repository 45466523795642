import { Card } from 'components/gsys-ui'
import { GlobalContext } from 'context/GlobalContext'
import { useInTransit } from 'util/queries'
import { useContext, useEffect, useState, useRef } from 'react'
import { BiTargetLock } from 'react-icons/bi'
import moment from 'moment'
import GoogleMap from 'google-maps-react-markers'
import Button from 'components/ui/Button'
import s from '../styles/SpeechBubble.module.scss'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css';

const Tracking = () => {
  const { uinfo } = useContext(GlobalContext)
  const mapContRef = useRef(null)
  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)
  const { isLoading, isError, data, error } = useInTransit(uinfo.CustomerKeyCode)
  const [mapWidth, setMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null)

  useEffect(() => {
    if (mapContRef.current) {
      setMapWidth(mapContRef.current.offsetWidth);
      setMapHeight(mapContRef.current.offsetHeight);
    }
  }, [mapReady])

  useEffect(() => {
    if (mapWidth === 0 || mapHeight === 0) return
    centerMap()
  }, [mapReady, mapWidth, mapHeight])

  if (isLoading) return <div className="col-span-5">Loading...</div>
  if (isError) return <div className="col-span-5">{JSON.stringify(error)}</div>

  const firstDrop = data[0] || false
  const allPoints = firstDrop ? [
    firstDrop.Loc[0],
    ...data.map((el) => el.DriverLoc[0].Location[0])
  ] : []

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
    setMapReady(true)
  }

  const centerMap = () => {
    console.info(`firstDrop: ${firstDrop.Loc[0].lat}, ${firstDrop.Loc[0].lon}`)
    if (!firstDrop) return
    if (!mapRef.current) return
    setSelectedMarker(null)
    let nwLat = -91
    let nwLng = 181
    let seLat = 91
    let seLng = -181

    for (const p of allPoints) {
      if (p.lat > nwLat) nwLat = p.lat
      if (p.lat < seLat) seLat = p.lat
      if (p.lon < nwLng) nwLng = p.lon
      if (p.lon > seLng) seLng = p.lon
    }

    const { center, zoom } = fitNwSe(
      { lat: nwLat, lng: nwLng },
      { lat: seLat, lng: seLng },
      mapWidth,
      mapHeight
    )

    mapRef.current.panTo(center)
    mapRef.current.setZoom(zoom)
  }

  const handleCardClick = (ind) => {
    if (selectedMarker === ind) {
      setSelectedMarker(null)
      centerMap()
    } else {
      setSelectedMarker(ind)
      const loc = data[ind].DriverLoc[0].Location[0]
      mapRef.current.panTo({ lat: loc.lat, lng: loc.lon })
      mapRef.current.setZoom(12)
    }
  }

  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <div className="col-span-3 space-y-2">
        <div className="">
          <Button onClick={centerMap}><BiTargetLock className="mr-2 w-5 h-5" />Recenter Map</Button>
        </div>
        {
          data.map((el, ind) => (
            <Card
              key={ind}
              hover
              noPad
              onClick={() => handleCardClick(ind)}
            >
              <div className="px-5 pt-5">
                <div className="flex justify-between">
                  <div className="text-xl font-bold">{el.Document[0]}</div>
                  <div className="text-lg">{el.Document.length > 1 && ` and ${el.Document.length - 1} other doc${el.Document.length > 2 ? 's' : ''}`}</div>
                </div>
                <div className="mt-2 space-y-1">
                  {
                    el.PartInfo.map((parts, ind) => (
                      <div key={ind} className="flex justify-between w-full text-lg">
                        <div>PART: {parts.Part}</div>
                        <div>QTY: {parts.Qty}</div>
                      </div>
                    ))
                  }
                </div>
                <div className="flex justify-between mt-4 text-2xl">
                  <div className="font-bold">E.T.A.</div>
                  <div>
                    {
                      moment(el.Eta).format("DD/MM/YYYY") == moment().format("DD/MM/YYYY") ? (
                        'Today at ' + moment(el.Eta).format("HH:mm")
                      ) : (
                        moment(el.Eta).format("DD/MM/YYYY HH:mm")
                      )
                    }
                  </div>
                </div>
              </div>
              <div
                className="mt-4 w-full h-1 transition-colors"
                style={{
                  backgroundColor: ind === selectedMarker ? 'red' : 'white'
                }}
              />
            </Card>
          ))
        }
      </div>

      <div className="col-span-9 space-y-2">
        <Card noPad className="h-full">
          <div className="h-full" ref={mapContRef}>
            <GoogleMap
              apiKey="AIzaSyCuk8jFrOel5RA7Nbyzm0PhD2aZ3VKnanA"
              onGoogleApiLoaded={onGoogleApiLoaded}
              defaultCenter={{ lat: 54.2361, lng: -2.5481 }}
              defaultZoom={6}
            >
              {firstDrop && <FactorIcon lat={51.66439} lng={-3.30417} />}
              {
                data.map((el, ind) => {
                  return (
                    <MechanicIcon
                      key={ind}
                      lat={el.DriverLoc[0].Location[0].lat}
                      lng={el.DriverLoc[0].Location[0].lon}
                      selected={selectedMarker === ind}
                      eta={Math.floor(el.Eta / 1000)}
                      start={moment(el.Date).unix()}
                      title={el.Document[0]}
                    />
                  )
                })
              }
            </GoogleMap>
          </div>
        </Card>
      </div>
    </div>
  )
}

const MechanicIcon = ({ selected, eta, start, title }) => {
  const now = Math.floor(Date.now() / 1000)
  const duration = eta - start
  const elapsed = now - start
  const remaining = eta - now
  const percent = (elapsed / duration) * 100
  const hours = Math.floor(remaining / 3600)
  const minutes = Math.floor((remaining % 3600) / 60)
  const seconds = remaining % 60
  const { brandingBlob } = useContext(GlobalContext)

  return (
    <div className="relative">
      {
        selected && (
          <div className={s.speechBubble}>
            <div className="mb-2 text-[14px] font-bold text-center">{title}</div>
            <CircularProgressbarWithChildren value={percent} className="w-32 h-32" counterClockwise={true}>
              <div className="leading-snug text-center">
                <div className="text-[14px]">E.T.A.</div>
                <div className="text-lg font-bold">{`${hours}h ${minutes}m`}</div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        )
      }
      <img src={brandingBlob} className="w-10 h-10" />

    </div >
  )
}

const FactorIcon = () => {


  return (
    <Card >
      <img src="/img/mechanic.png" className="w-10 h-10" />
    </Card>
  )
}

const GOOGLE_TILE_SIZE = 256;

function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y = y < 0 // eslint-disable-line
    ? 0
    : y > 1
      ? 1
      : y;
  return { x, y };
}

function world2LatLng({ x, y }) {
  const n = Math.PI - 2 * Math.PI * y;

  // TODO test that this is faster
  // 360 * Math.atan(Math.exp((180 - y * 360) * Math.PI / 180)) / Math.PI - 90;
  return {
    lat: Math.round(((180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))) * 10000000) / 10000000,
    lng: Math.round((x * 360 - 180) * 10000000) / 10000000
  };
}

const log2 = Math.log2 ? Math.log2 : (x) => Math.log(x) / Math.LN2;

function fitNwSe(nw, se, width, height) {
  const EPS = 0.000000001;
  const nwWorld = latLng2World(nw);
  const seWorld = latLng2World(se);
  const dx =
    nwWorld.x < seWorld.x ? seWorld.x - nwWorld.x : 1 - nwWorld.x + seWorld.x;
  const dy = seWorld.y - nwWorld.y;

  if (dx <= 0 && dy <= 0) {
    return null;
  }

  const zoomX = log2(width / GOOGLE_TILE_SIZE / Math.abs(dx));
  const zoomY = log2(height / GOOGLE_TILE_SIZE / Math.abs(dy));
  const zoom = Math.floor(EPS + Math.min(zoomX, zoomY));

  // TODO find center just unproject middle world point
  const middle = {
    x: nwWorld.x < seWorld.x // eslint-disable-line
      ? 0.5 * (nwWorld.x + seWorld.x)
      : nwWorld.x + seWorld.x - 1 > 0
        ? 0.5 * (nwWorld.x + seWorld.x - 1)
        : 0.5 * (1 + nwWorld.x + seWorld.x),
    y: 0.5 * (nwWorld.y + seWorld.y),
  };

  const scale = Math.pow(2, zoom);
  const halfW = width / scale / GOOGLE_TILE_SIZE / 2;
  const halfH = height / scale / GOOGLE_TILE_SIZE / 2;

  const newNW = world2LatLng({
    x: middle.x - halfW,
    y: middle.y - halfH,
  });

  const newSE = world2LatLng({
    x: middle.x + halfW,
    y: middle.y + halfH,
  });

  return {
    center: world2LatLng(middle),
    zoom,
    newBounds: {
      nw: newNW,
      se: newSE,
    },
  };
}

export default Tracking