import { Field, ErrorMessage } from 'formik'
import clsx from 'clsx'

import styles from 'components/ui/form/RadioButton.module.scss'

const RadioButton = ({ name, label, value }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center">
        <Field
          className={clsx('w-8 h-8 rounded-full border-[2px] border-gray-300 appearance-none relative after:bg-blue-500 checked:border-blue-500 transition-colors', styles.radioButton)}
          style={{}}
          type="radio"
          name={name}
          value={value}
        />
        <div className="font-semibold">{label}</div>
      </div>

      <div className="text-sm text-red-600">
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}

export default RadioButton