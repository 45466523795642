import { createContext, useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { getPartList } from 'util/api';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // Company and User Information
  const [cinfo, setCinfo] = useState(null);
  const [uinfo, setUinfo] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [brandingBlob, setBrandingBlob] = useState(null);

  // Parts and Lists Management
  const [quickLaunchParts, setQuickLaunchParts] = useLocalStorageState('quickLaunchParts', { defaultValue: [] });
  const [partLists, setPartLists] = useState([]);
  const [partList, setPartList] = useState({});

  // API and Request Information
  const [apir, setApir] = useState(null);
  const [devId, setDevId] = useState(null);

  // UI States
  const [selectedEnq, setSelectedEnq] = useState(null);
  const [inTransit, setInTransit] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const [error, setError] = useState(null);

  // Additional Information
  const [vehicleString, setVehicleString] = useState('');
  const [notesExtra, setNotesExtra] = useState('');
  const version = '1.1.2';

  // Currency symbol effect
  useEffect(() => {
    if (!cinfo) return;
    cinfo.CurrencyCode === 'GBP' ? setCurrencySymbol('£') : setCurrencySymbol('€');
  }, [cinfo]);

  // Quick launch parts management
  const addQuickLaunchPart = (part) => {
    if (quickLaunchParts.includes(part)) return false;
    const newParts = [...quickLaunchParts, part];
    setQuickLaunchParts(newParts);
  };

  const deleteQuickLaunchPart = (part) => {
    let newParts = quickLaunchParts.filter((p) => p !== part);
    setQuickLaunchParts(newParts);
  };

  // Part list loading effect
  useEffect(() => {
    if (apir === null) return;

    const loadPartList = async () => {
      try {
        setError(null);
        const data = (await getPartList(apir)).data;
        const formattedPartList = data.reduce((acc, item) => {
          acc[item.PartGroup] = item.PartList;
          return acc;
        }, {});

        setPartLists(formattedPartList);
        setPartList(formattedPartList); // Update both state variables for compatibility
      } catch (error) {
        console.error('Error loading part list:', error);
        setError('Failed to load parts. Refresh to try again.');
      }
    };

    loadPartList();
  }, [apir]);

  const value = {
    // Company and User Information
    cinfo,
    setCinfo,
    uinfo,
    setUinfo,
    currencySymbol,
    brandingBlob,
    setBrandingBlob,

    // Parts and Lists Management
    quickLaunchParts,
    addQuickLaunchPart,
    deleteQuickLaunchPart,
    partLists,
    setPartLists,
    partList,
    setPartList,

    // API and Request Information
    apir,
    setApir,
    devId,
    setDevId,

    // UI States
    selectedEnq,
    setSelectedEnq,
    inTransit,
    setInTransit,
    receipt,
    setReceipt,
    error,

    // Additional Information
    vehicleString,
    setVehicleString,
    notesExtra,
    setNotesExtra,
    version,
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};