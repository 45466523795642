import { useContext } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { UserPlusIcon } from '@heroicons/react/24/outline'

import Card from 'components/ui/Card'
import { Form } from 'components/ui/form/Form'
import EnquiryForm from 'components/EnquiryColumn/EnquiryForm'
import { enquiryValidation } from 'util/validations'
import ModalContainer from '../ui/ModalContainer'
import { GlobalContext } from 'context/GlobalContext'
import * as api from 'util/api'
import { useCreateEnquiry } from 'util/mutations'

const NewEnquiryModal = NiceModal.create(({ initial, loadEnqs }) => {
  const modal = useModal()
  const { brandingBlob } = useContext(GlobalContext)
  const mutation = useCreateEnquiry()

  const handleSubmit = async (res) => {
    modal.remove()
  }

  const Logo = () => <img className="mr-3 h-12 rounded-md" src={brandingBlob} alt="logo" />

  return (
    <ModalContainer>
      <Card className="!w-[700px]" pad={false}>
        <Card.Title Icon={Logo}>New Enquiry</Card.Title>
        <Card.Body>
          <Form
            validation={enquiryValidation}
            initial={initial}
            mutation={mutation}
            FormComp={EnquiryForm}
            onSubmit={handleSubmit}
          />
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

export default NewEnquiryModal
