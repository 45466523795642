import clsx from 'clsx'

const Input = ({ value, onChange, placeholder, ...props }) => (
  <input
    className={clsx(
      'p-3 rounded-md border border-gray-300 focus:outline-none w-full focus:border-blue-400 transition-colors text-lg',
      props.className || ''
    )}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onKeyUp={props.onKeyUp}
  />
)

export default Input