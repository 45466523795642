import { useState, useEffect, useContext } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import BulletinCard from 'components/NewsColumn/BulletinCard'
import { useGetReturnsPartList } from 'util/queries'
import moment from 'moment'
import Spinner from '../ui/Spinner'

const ReturnsColumn = () => {
    const { uinfo, apir, cinfo, navigation } = useContext(GlobalContext) // Assumed cinfo and navigation are part of the context
    const [partSummary, setPartSummary] = useState(null)
    const { isLoading, isError, data: returns, error } = useGetReturnsPartList(uinfo.CustomerKeyCode)

    const getReturnsSummary = (returns) => {
        let totalQty = 0;
        let qtyByReason = {};

        if (!Array.isArray(returns)) {
            return { totalQty, qtyByReason };  // Return default values if returns isn't an array
        }

        returns.forEach(returnItem => {
            returnItem.Parts.forEach(part => {
                totalQty += Number(part.Qty);

                if (!qtyByReason[part.Reason]) {
                    qtyByReason[part.Reason] = 0;
                }

                qtyByReason[part.Reason] += Number(part.Qty);
            });
        });

        return {
            totalQty,
            qtyByReason,
        };
    }

    useEffect(() => {
        if (returns) {
            const summary = getReturnsSummary(returns);
            console.log(summary);
            setPartSummary(summary);
        }
    }, [returns])

    if (isError) return <div className="col-span-4">{JSON.stringify(error)}</div>

    if (isLoading) return <Spinner />

    return (
        <div style={{ flex: 1, backgroundColor: cinfo.ConnectPlusColor }}>
            {apir === 'none' ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90%', backgroundColor: cinfo.ConnectPlusColor }}>
                    <div style={{ justifyContent: 'center', padding: 5, textAlign: 'center' }}>
                        <p style={{ color: 'white', fontFamily: 'Ubuntu_700Bold', fontSize: 22, textTransform: 'uppercase', padding: 5 }}>
                            Contact {cinfo.CompanyName} for more information on viewing Warranty / returns, PoD signatures & payment receipts
                        </p>
                    </div>
                    <button
                        onClick={() => navigation.navigate('HomeScreen')}
                        style={{ margin: 5, padding: 10, borderRadius: 12, backgroundColor: 'grey', color: 'black' }}
                    >
                        CLOSE
                    </button>
                </div>
            ) : (
                <>
                    <div style={{ flex: 1, overflowY: 'scroll' }}>
                        <div style={styles.cardWrap}>
                            {partSummary && (
                                <>
                                    <p style={styles.subtitle}>Total Quantity: {partSummary.totalQty} (last 3 months)</p>
                                    <p style={styles.subtitle}>Total by Reason:</p>
                                    {Object.entries(partSummary.qtyByReason).map(([key, value], i) => (
                                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, paddingBottom: 10 }}>
                                            <span>{key}</span>
                                            <span>{value}</span>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div>
                            {Array.isArray(returns) && returns.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime)).map((item, i) => (
                                <div key={i} style={{ marginTop: 20, ...styles.cardWrap }}>
                                    <p style={styles.dateText}>{moment(item.DateTime).format('dddd Do MMM HH:mm')}</p>
                                    {item.Parts.map((part, j) => (
                                        <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                            <span>Part:  <span style={{ fontWeight: 'bold' }}>{part.PartNo}</span></span>
                                            <span>Qty: <span style={{ fontWeight: 'bold' }}>{part.Qty}</span></span>
                                            <span>Reason: <span style={{ fontWeight: 'bold' }}>{part.Reason}</span></span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={() => navigation.navigate('HomeScreen')}
                        style={{ margin: 5, padding: 10, borderRadius: 12, color: 'black' }}
                    >
                        CLOSE
                    </button>
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        // Add your container styles here
    },
    cardWrap: {
        // Add your cardWrap styles here
    },
    subtitle: {
        // Add your subtitle styles here
    },
    dateText: {
        // Add your dateText styles here
    },
};

export default ReturnsColumn