import { useRef } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import clsx from 'clsx'
import moment from 'moment'

import Spinner from 'components/ui/Spinner'
import useBlobImage from 'hooks/useBlobImage'
import useOnScreen from 'hooks/useOnScreen'

const Message = ({ blobId, text, timestamp, showTimestamp, showDatestamp, self = true }) => {
  const hasImage = !!blobId

  return (
    <div>
      {
        showDatestamp && (
          <div className="flex relative justify-center items-center">
            <div className="h-[1px] bg-gray-300 w-[80%] absolute" />
            <div className="z-50 p-2 text-xs text-gray-400 bg-white">{moment(timestamp).format('DD/MM/YY')}</div>
          </div>
        )
      }
      <div
        className={clsx(
          'flex flex-col items-end',
          { '!items-start': !self }
        )}
        title={moment(timestamp).format('DD/MM/YY HH:mm')}
      >
        <div
          className={clsx(
            'p-1 text-lg text-white bg-blue-500 max-w-[66%]',
            {
              'w-[66%]': hasImage,
              'bg-yellow-400 !text-inherit': !self
            }
          )}
          style={{ borderRadius: '12px' }}
        >
          {hasImage && <ImageBox blobId={blobId} />}
          {text && <div className={clsx('px-1', { 'py-1': hasImage })}>{text}</div>}
        </div>
        {showTimestamp && <div className="text-sm mt-[-2px] mb-2">{moment(timestamp).format('HH:mm')}</div>}
      </div>
    </div>
  )
}

const ImageBox = ({ blobId }) => {
  const boxRef = useRef(null)
  const isIntersecting = useOnScreen(boxRef)
  const [imageData, imageIsLoading] = useBlobImage(blobId, isIntersecting)

  const openModal = () => {
    NiceModal.show('lightboxModal', { blobId })
  }

  if (imageIsLoading) return (
    <div
      ref={boxRef}
      className="flex justify-center items-center w-full aspect-square"
      style={{
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '10px'
      }}
    >
      <Spinner color="rgba(0,0,0,0.2)" />
    </div>
  )

  return (
    <div
      onClick={openModal}
      className="overflow-hidden relative w-full rounded-xl cursor-pointer aspect-square"
      style={{
        borderRadius: '10px'
      }}
    >
      <div
        className="absolute bg-center bg-cover"
        style={{
          top: '-20px',
          right: '-20px',
          bottom: '-20px',
          left: '-20px',
          backgroundImage: `url(${imageData})`,
        }}
      />
    </div>
  )
}

export default Message