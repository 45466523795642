import clsx from 'clsx'

import Button from '../Button/Button'
import s from './Input.module.scss'

export const BaseInput = (props) => {
  return (
    <input
      className={clsx(s.input, props.className)}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onKeyUp={props.onKeyUp}
    />
  )
}

export const Input = (props) => {
  return (
    <div className={s.inputGroup}>
      {
        props.label && (
          <div className={s.label}>{props.label}</div>
        )
      }
      <BaseInput {...props} />
      <div className={s.error}>{props.error}</div>
    </div>
  )
}

export const ButtonInput = ({ children, ...props }) => {
  return (
    <div className={s.buttonInputGroup}>
      <Input
        className={props.className}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
      />
      <Button
        className={clsx(s.button)}
        variant={props.variant}
        onClick={props.onClick}
      >
        {children}
      </Button>
    </div>
  )
}