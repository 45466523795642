import { Field, ErrorMessage } from 'formik'

const TextInput = ({ name, label, required, placeholder }) => (
  <div className="w-full">
    <div className="text-sm font-semibold">
      {label}
    </div>
    <Field
      name={name}
      aria-label={`Enter your ${label}`}
      aria-required={required}
      placeholder={placeholder}
      type="text"
      className="p-2 w-full text-sm font-normal bg-white rounded border border-gray-300 transition-colors focus:border-blue-500 focus:outline-none"
    />

    <div className="text-sm text-red-600">
      <ErrorMessage name={name} />
    </div>
  </div>
)

export default TextInput