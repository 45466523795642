import Spinner from '../ui/Spinner'
import { useBulletinsId } from 'util/queries'
import { useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'


const NewsDetailsColumn = () => {
    const { newsId } = useParams()

    const { isLoading, isError, data: bulletin, error } = useBulletinsId(newsId)

    const styledContent = `
    <style>
        body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    </style>
    ${bulletin?.data?.[0]?.Content}
    
`;

    const iframeRef = useRef(null);

    useEffect(() => {
        //console.log("Styled Content Updated:", styledContent);

        if (iframeRef.current) {
            iframeRef.current.contentWindow.document.open();
            iframeRef.current.contentWindow.document.write(styledContent);
            iframeRef.current.contentWindow.document.close();
        }
    }, [styledContent]);
    if (isError) return <div className="col-span-4">Sorry - couldn't find any news for you.</div>

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            {styledContent && (
                <iframe
                    style={{ width: '80%', height: '80%', border: 'none' }}
                    srcDoc={styledContent}
                    title="bulletin"
                    frameBorder="0"
                />
            )}
        </div>
    )
}

export default NewsDetailsColumn;
