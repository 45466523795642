import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import Card from 'components/ui/Card'
import * as api from 'util/api'
import { GlobalContext } from 'context/GlobalContext'
import MessageBox from 'components/MessagesColumn/MessageBox'
import ComposeMessage from 'components/MessagesColumn/ComposeMessage'
import Spinner from 'components/ui/Spinner'
import { EnquiriesContext } from 'context/EnquiriesContext'
import { useMessages } from 'util/queries'

const MessagesColumn = () => {
  const { cinfo } = useContext(GlobalContext)
  const { enqId } = useParams()
  const { isLoading, isError, data: messages, error } = useMessages(enqId)

  return (
    <div className="col-span-3 h-auto min-h-full">
      <Card pad={false} className="flex overflow-hidden relative flex-col h-full">
        <div className="flex justify-between p-4 text-xl font-medium text-white uppercase" style={{ backgroundColor: cinfo.ConnectPlusColor }}>
          <div>Messages</div>
          <div></div>
        </div>

        {
          isLoading ? (
            <div className="flex justify-center p-4 h-full">
              <Spinner />
            </div>
          ) : !messages || messages.length === 0 ? (
            <div className="p-4 h-full text-lg italic text-center text-gray-500">Need help? Send us a message below!</div>
          ) : (
            <MessageBox messages={messages} />
          )
        }

        <ComposeMessage loadMessages={() => { }} />
      </Card>
    </div >
  )
}

export default MessagesColumn