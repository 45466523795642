import { useParams } from 'react-router-dom'

import EnquiryColumn from 'components/EnquiryColumn/EnquiryColumn'
import DetailsColumn from 'components/DetailsColumn/DetailsColumn'
import MessagesColumn from 'components/MessagesColumn/MessagesColumn'
import '../util/modals'

const Home = () => {
  const { enqId } = useParams()

  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <EnquiryColumn />
      <DetailsColumn />
      {enqId && <MessagesColumn />}
    </div>
  )
}

export default Home