import clsx from 'clsx'

import Input from 'components/ui/Input'
import Button from 'components/ui/Button'

const InputWithButton = ({ placeholder, value, onChange, onClick, children, ...props }) => {
  return (
    <div
      className={clsx(
        'flex w-full',
        props.className || ''
      )}
    >
      <Input
        className="flex-1 rounded-r-none border-r-0"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <Button
        className="flex-none rounded-l-none"
        blue
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  )
}

export default InputWithButton