import NiceModal from '@ebay/nice-modal-react'

import LightboxModal from 'components/MessagesColumn/LightboxModal'
import NewEnquiryModal from 'components/EnquiryColumn/NewEnquiryModal'
import DateFilterModal from 'components/EnquiryColumn/DateFilterModal'
import PartListModal from 'components/EnquiryColumn/PartListModal'
import { MakeModelModal } from 'components/EnquiryColumn/MakeModelModal'

NiceModal.register('partListModal', PartListModal)
NiceModal.register('lightboxModal', LightboxModal)
NiceModal.register('newEnquiryModal', NewEnquiryModal)
NiceModal.register('dateFilterModal', DateFilterModal)
NiceModal.register('makeModelModal', MakeModelModal)