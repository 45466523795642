import axios from 'axios'
//import * as RootNavigation from '../util/RootNavigation'
import { BASE_URL } from './config'

let token
const instance = axios.create()

export const getToken = async (shouldNavigate = true) => {
  const devId = localStorage.getItem('onesignalId')

  try {
    const response = await axios({
      url: `${BASE_URL}/api/authenticate/mobile/${devId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    token = response.data.token
    //console.info('WOOO, TOKEN: ' + token)
  } catch (error) {
    console.error('Error getting token', error)

    if ((error.response.status === 406 /*|| error.response.status === 401*/) && shouldNavigate) {
      if (window.location.pathname !== '/login') window.location = '/login'
    } else {
      throw error
    }
  }
}

instance.interceptors.request.use(
  async (config) => {
    if (!token) await getToken()
    config.headers.token = token
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    console.error(JSON.stringify(error))

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await getToken()
      originalRequest.headers.token = token
      originalRequest.headers.Authorization = `Bearer ${token}`
      return instance(originalRequest)
    }

    if (error.response.status === 401) {
      if (window.location.pathname !== '/login') window.location = '/login'
      return
    }

    return Promise.reject(error)
  }
)

export default instance