import { ButtonInput, Card } from 'components/gsys-ui'
import { GlobalContext } from 'context/GlobalContext'
import { useContext, useState } from 'react'
import moment from 'moment'
import { InformationCircleIcon, PlusIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import s from '../styles/QuickLaunchList.module.scss'

const Settings = () => {
  const { cinfo, uinfo, version, quickLaunchParts, addQuickLaunchPart, deleteQuickLaunchPart } = useContext(GlobalContext)
  const [part, setPart] = useState('')

  const handleAddPart = () => {
    addQuickLaunchPart(part)
    setPart('')
  }

  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <div className="col-span-4 space-y-2">
        <Card noPad>
          <Card.Title Icon={InformationCircleIcon}>Support Info</Card.Title>
          <Card.Body>
            <div className="mb-2">You can get support by calling your local {cinfo.CompanyName} branch, or you can get in touch with the app development team at:</div>
            <div className="font-semibold">support@automm.co.uk</div>
            <a className="font-semibold" href="https://automm.co.uk" target="_blank" rel="noopener noreferrer">automm.co.uk</a>
          </Card.Body>
        </Card>
        <Card noPad>
          <Card.Title Icon={UserIcon}>Your Info</Card.Title>
          <Card.Body>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="py-1 font-semibold">Garage Name</td>
                  <td>{uinfo.GarageName}</td>
                </tr>
                <tr>
                  <td className="py-1 font-semibold">Username</td>
                  <td>{uinfo.GarageUserName}</td>
                </tr>
                <tr>
                  <td className="py-1 font-semibold align-top">Address</td>
                  <td>{uinfo.Address.split('\n').map((el, ind) => <div key={ind} className="leading-snug">{el}</div>)}</td>
                </tr>
                <tr>
                  <td className="py-1 font-semibold">Account Created</td>
                  <td>{moment(uinfo.CreatedDatetime).format("DD/MM/YY")}</td>
                </tr>
                <tr>
                  <td className="py-1 font-semibold">Software Version</td>
                  <td>{version}</td>
                </tr>
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </div>

      <div className="col-span-4 space-y-2">
        <Card noPad>
          <Card.Title Icon={PlusIcon}>Add Parts to Shortcuts</Card.Title>
          <Card.Body>
            <ButtonInput
              placeholder="Enter part to save..."
              value={part}
              onChange={(e) => setPart(e.target.value)}
              onClick={handleAddPart}
            >
              <PlusIcon className="w-5 h-5" />
            </ButtonInput>
            <div className="flex flex-wrap mt-2">
              {
                quickLaunchParts.map((p, ind) => (
                  <div key={ind} className="inline-flex items-center px-4 py-3 m-1 font-semibold text-white uppercase bg-orange-400 rounded-full select-none">
                    <div>{p}</div>
                    <XMarkIcon className="ml-1 w-5 h-5 text-white cursor-pointer" onClick={() => deleteQuickLaunchPart(p)} />
                  </div>
                ))
              }
            </div>
          </Card.Body>
        </Card>
      </div>
    </div >
  )
}

export default Settings