import Card from 'components/ui/Card'

const EnqWaiting = () => {
  return (
    <Card>
      <div className="text-lg text-center">We have received your enquiry and will respond as quickly as possible.</div>
    </Card>
  )
}

export default EnqWaiting