import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

const ApirError = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <ExclamationCircleIcon className="mb-4 w-28 h-28 text-red-400" />
      <div className="mb-3 text-2xl font-bold">Sorry, something went wrong</div>
      <div className="mb-1 text-md">We were unable to load the APIR</div>
      <div className="text-md">Please contact AutoMM on <span className="font-bold">0121 285 9454</span></div>
    </div>
  )
}

export default ApirError