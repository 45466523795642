import { useRef } from 'react'
import { useField } from 'formik'
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/gsys-ui'

const ImagePicker = ({ name, label }) => {
  const [field, meta, helpers] = useField(name)
  const pickerRef = useRef(null)

  const handlePressRemoveAttach = () => {
    helpers.setValue(null)
    pickerRef.current.value = ''
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    helpers.setValue(file)
  }

  const handlePressAttach = () => {
    pickerRef.current.click()
  }

  return (
    <div className="flex justify-end items-center space-x-2">
      {
        field.value && (
          <>
            <XMarkIcon
              className="ml-1 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
              onClick={handlePressRemoveAttach}
            />
            <img className="object-cover w-9 h-9 rounded-lg" src={URL.createObjectURL(field.value)} />
          </>
        )
      }
      <input
        type="file"
        className="hidden"
        accept="image/*"
        ref={pickerRef}
        onChange={handleChangeFile}
      />
      <Button onClick={handlePressAttach}>
        <PaperClipIcon
          className="mr-2 w-5 h-5 cursor-pointer text-whitetransition-colors hover:text-gray-500"
        />
        Attach image
      </Button>
    </div>
  )
}

export default ImagePicker