import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { PaperAirplaneIcon, PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline'

import Spinner from 'components/ui/Spinner'
import { useSendMessage } from 'util/mutations'

const ComposeMessage = ({ loadMessages }) => {
  const { enqId } = useParams()
  const [isSending, setSending] = useState(null)
  const [messageText, setMessageText] = useState('')
  const [pickedImage, setPickedImage] = useState(false)
  const pickerRef = useRef(null)
  const mutation = useSendMessage(enqId)

  const handlePressSend = async () => {
    if (!messageText && !pickedImage) return
    setSending(true)
    const msg = { text: messageText, image: pickedImage }
    await mutation.mutateAsync(msg)
    setPickedImage(null)
    setMessageText('')
    setSending(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handlePressSend()
    }
  }

  const handlePressAttach = () => {
    pickerRef.current.click()
  }

  const handlePressRemoveAttach = () => {
    setPickedImage(false)
    pickerRef.current.value = ''
  }

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    setPickedImage(file)
  }

  return (
    <div className="p-3 border-t border-gray-300">
      {
        pickedImage && (
          <div className="flex justify-end items-center mb-2">
            <img className="object-cover w-10 h-10 rounded-lg" src={URL.createObjectURL(pickedImage)} alt="picked image" />
            <XMarkIcon
              className="ml-1 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
              onClick={handlePressRemoveAttach}
            />
          </div>
        )
      }
      <div className="flex items-center">
        <div className="flex items-end flex-0">
          <input
            type="file"
            className="hidden"
            accept="image/*"
            ref={pickerRef}
            onChange={handleChangeFile}
          />
          <PaperClipIcon
            className="mr-3 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
            onClick={handlePressAttach}
          />
        </div>
        <textarea
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyDown={handleKeyDown}
          className="flex-1 outline-none resize-none"
          placeholder="Write a message..."
          rows={messageText.split("\n").length}
        />
        <div className="flex items-end flex-0">
          {
            isSending ? (
              <div className="mr-1.5">
                <Spinner size={20} />
              </div>
            ) : (
              <PaperAirplaneIcon
                className="ml-3 w-7 h-7 text-gray-400 transition-colors cursor-pointer hover:text-gray-500"
                onClick={handlePressSend}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ComposeMessage