import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button } from 'components/gsys-ui'
import TextInput from 'components/ui/form/TextInput'
import { useState } from 'react'
import { Formik } from 'formik'

export const MakeModelModal = NiceModal.create(({ onSubmit }) => {
    const modal = useModal();
    const [error, setError] = useState('');

    const handleSubmit = (values) => {
        if (!values.make || !values.model) {
            setError('Please enter both make and model');
            return;
        }
        onSubmit(values);
        modal.remove();
    };

    const initialValues = {
        make: '',
        model: ''
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96">
                <h2 className="text-xl font-bold mb-4">Enter Vehicle Details</h2>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col gap-4">
                                <TextInput
                                    name="make"
                                    label="Make"
                                    placeholder="Enter vehicle make"
                                />
                                <TextInput
                                    name="model"
                                    label="Model"
                                    placeholder="Enter vehicle model"
                                />

                                {error && <p className="text-red-500 text-sm">{error}</p>}

                                <div className="flex justify-end gap-3 mt-4">
                                    <Button variant="white" onClick={modal.remove} type="button">
                                        Cancel
                                    </Button>
                                    <Button type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
});

export default MakeModelModal;