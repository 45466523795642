import { useState } from 'react'
import { Formik } from 'formik'

export const Form = ({ validation, initial = {}, mutation, FormComp, onSubmit: submitCallback }) => {
  const [error, setError] = useState(null)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    let res

    try {
      res = await mutation.mutateAsync(values)
    } catch (e) {
      console.log(e)
      setError('Unknown error')
      setSubmitting(false)
      return
    }

    //setError(res.error?.message || null)
    setSubmitting(false)
    if (submitCallback) submitCallback(res)
  }

  const initialValues = {
    ...validation.cast({}),
    ...initial
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {
        (formik) => (
          <FormComp
            error={error}
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
          />
        )
      }
    </Formik>
  )
}

export const Error = ({ children }) => (
  <div className="p-2 text-center text-red-400 rounded text-md">
    {children}
  </div>
)

export const Title = ({ children, Icon }) => (
  <div className="flex items-center text-lg font-semibold">
    {Icon && <Icon className="mr-2 w-9 h-9" />}
    <span>{children}</span>
  </div>
)

export const Header = ({ children, Icon }) => (
  <div className="flex items-center font-semibold text-md">
    {Icon && <Icon className="mr-2 w-7 h-7" />}
    <span>{children}</span>
  </div>
)

export const Row = ({ children }) => (
  <div className="flex space-x-2">
    {children}
  </div>
)

export const Spacer = () => (
  <div className="h-4" />
)

export const Footer = ({ children }) => (
  <div className="flex justify-end items-center space-x-2 w-full">
    {children}
  </div>
)

export const VSpacer = () => (
  <div className="w-full"></div>
)