import moment from 'moment'

import Message from 'components/MessagesColumn/Message'

const MessageBox = ({ messages }) => {
  return (
    <div className="flex overflow-y-scroll flex-col-reverse flex-1 gap-0.5 px-3 py-2">
      {
        messages.map((message, ind) => {
          // the first item is the most recent message
          // we go backwards through the array to go forwards through the messages
          const nextMessage = messages[ind - 1] || false
          const prevMessage = messages[ind + 1] || false
          const isFirstMessage = ind === messages.length - 1
          const currentMessageTime = moment(message.Datetime)

          // show timestamp if:
          // - message is the most recent
          // - difference between this and the previous message is greater than 60 seconds
          const withTimestamp = nextMessage === false ? true : (
            moment(nextMessage.Datetime).diff(currentMessageTime, 'seconds') > 60
          )

          // show datestamp if
          // - message is the earliest AND the date on it is not today
          // - message is not the earliest AND on a different day to the prior message
          const withDatestamp = (
            isFirstMessage &&
            !currentMessageTime.isSame(moment(), 'day')
          ) || (
              !isFirstMessage &&
              !moment(prevMessage.Datetime).isSame(currentMessageTime, 'day')
            )

          return (
            <Message
              key={message.MessageId}
              blobId={message.BlobStorageId}
              text={message.Text}
              timestamp={message.Datetime}
              showTimestamp={withTimestamp}
              showDatestamp={withDatestamp}
              self={message.Garage && !message.Factor}
            />
          )
        })
      }
    </div>
  )
}

export default MessageBox