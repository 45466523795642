import { useContext, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Card from 'components/ui/Card'
import ModalContainer from '../ui/ModalContainer'
import { GlobalContext } from 'context/GlobalContext'
import InputWithButton from 'components/ui/InputWithButton'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import Button from 'components/ui/Button'
import { Footer } from 'components/ui/form/Form'

const PartListModal = NiceModal.create(({ submit }) => {
  const modal = useModal()
  const [searchValue, setSearchValue] = useState('')
  const { partLists } = useContext(GlobalContext)
  const [selectedLists, setSelectedLists] = useState([])

  const handleSubmit = async () => {
    const partsText = selectedLists.map((key) => partLists[key].join(', ')).join(', ')
    modal.remove()
    submit(partsText)
  }

  const partListsFiltered = searchValue
    ? Object.keys(partLists).filter((key) => {
        const list = partLists[key]
        const listText = list.join(' ')
        const searchString = `${key} ${listText}`.toLowerCase()
        if (searchString.includes(searchValue.toLowerCase())) return true
        return false
      })
    : Object.keys(partLists)

  const handleAddList = (key) => {
    setSelectedLists([...selectedLists, key])
  }

  const handleRemoveList = (key) => {
    setSelectedLists(selectedLists.filter((el) => el !== key))
  }

  const toggleList = (key) => {
    if (selectedLists.includes(key)) {
      handleRemoveList(key)
    } else {
      handleAddList(key)
    }
  }

  return (
    <ModalContainer>
      <Card className="!w-[500px]" pad={false}>
        <Card.Title>Select part list</Card.Title>
        <Card.Body>
          <InputWithButton placeholder="Search..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)}>
            <MagnifyingGlassIcon className="w-5 h-5" />
          </InputWithButton>

          <div className="h-[500px] overflow-y-scroll mt-2">
            {partListsFiltered.map((key) => (
              <PartList
                ind={key}
                name={key}
                parts={partLists[key]}
                submit={() => toggleList(key)}
                isSelected={selectedLists.includes(key)}
              />
            ))}
          </div>

          <div className="mt-4">
            <div className="font-semibold">
              {selectedLists.length} list{selectedLists.length !== 1 ? 's' : ''} selected.
            </div>
            <div>{selectedLists.join(', ')}</div>
          </div>

          <div className="mt-4">
            <Footer>
              <Button variant="white" onClick={modal.remove}>
                Cancel
              </Button>
              <Button variant="green" onClick={handleSubmit} disabled={selectedLists.length === 0}>
                Add
              </Button>
            </Footer>
          </div>
        </Card.Body>
      </Card>
    </ModalContainer>
  )
})

const PartList = ({ name, parts, submit, isSelected }) => {
  const partsText = parts.join(', ')

  return (
    <div className="py-3 border-b border-b-gray-300">
      <div className="text-2xl font-bold">{name}</div>
      <div>{partsText}</div>
      <div className="flex justify-end mt-2">
        {isSelected ? (
          <Button variant="white" onClick={submit}>
            Remove
          </Button>
        ) : (
          <Button variant="blue" onClick={submit}>
            Select
          </Button>
        )}
      </div>
    </div>
  )
}

export default PartListModal
