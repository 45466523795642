import clsx from 'clsx'

import s from './Card.module.scss'

const Card = ({ children, ...props }) => {
  return (
    <div
      className={clsx(
        s.card,
        props.noPad && s.noPad,
        props.hover && s.hover,
        props.className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const Title = ({ Icon, children }) => {
  return (
    <div className={s.title}>
      {
        Icon && (
          <Icon className={s.icon} width="28px" height="28px" />
        )
      }
      <div className={s.content}>
        {children}
      </div>
    </div>
  )
}

const Body = ({ children }) => {
  return (
    <div className={s.body}>
      {children}
    </div>
  )
}

Card.Title = Title
Card.Body = Body

export default Card