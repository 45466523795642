import { useParams } from 'react-router-dom'

import ReturnsColumn from 'components/ReturnsColumn/ReturnsColumn'
import '../util/modals'

const Returns = () => {
  const { newsId } = useParams()

  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <div className="col-span-4">
        <ReturnsColumn />
      </div>
    </div>
  )
}

export default Returns;
