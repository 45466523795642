import styles from './Spinner.module.scss'

const Spinner = ({ size = 40, color = 'rgba(0,0,0,0.2)' }) => {
  const width = size < 30 ? 3 : 5

  return (
    <div
      className={styles.spinner}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderColor: `${color} ${color} transparent ${color}`,
        borderWidth: `${width}px`
      }}
    />
  )
}

export default Spinner