import { useState, useEffect } from 'react'
import * as api from 'util/api'

const useBlobImage = (blobId, load = true) => {
  const [imageData, setImageData] = useState(null)
  const isLoading = !imageData

  useEffect(() => {
    if (!blobId || !load) return

    api.getImageFromBlobId(blobId).then((data) => {
      setImageData(data)
    })
  }, [blobId, load])

  return [imageData, isLoading]
}

export default useBlobImage