import { createContext, useState, useEffect } from 'react'
import OneSignal from 'react-onesignal'

import { ONESIGNAL_ID } from 'util/config'

export const OneSignalContext = createContext()

export const OneSignalProvider = ({ children }) => {
  const [oneSignalUserId, setOneSignalUserId] = useState(false)

  useEffect(() => {
    // ONESIGNAL SUX!!!!!
    /*const startOneSignal = async () => {
      console.log(ONESIGNAL_ID)
      await OneSignal.init({
        appId: ONESIGNAL_ID,
        allowLocalhostAsSecureOrigin: true, // remove for production, option allows us to run it localhost
        notificationClickHandlerAction: 'focus'
      })

      OneSignal.showSlidedownPrompt()
      const currentState = await OneSignal.getNotificationPermission()

      if (currentState === 'granted') {
        //setOneSignalUserId('02d90402-9539-42ac-95f8-6e17b4c58b9a')
        setOneSignalUserId(await OneSignal.getUserId())
      } else {
        OneSignal.once('subscriptionChange', async () => {
          setOneSignalUserId(await OneSignal.getUserId())
        })
      }
    }

    startOneSignal()*/
    const localId = localStorage.getItem('onesignalId')
    const isValid = localId ? !!localId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i) : false

    if (isValid) {
      setOneSignalUserId(localId)
    } else {
      const newUuid = crypto.randomUUID()
      setOneSignalUserId(newUuid)
    }
  }, [])

  useEffect(() => {
    if (!oneSignalUserId) return
    localStorage.setItem('onesignalId', oneSignalUserId)
  }, [oneSignalUserId])

  return (
    <OneSignalContext.Provider value={{ userId: oneSignalUserId }}>
      {children}
    </OneSignalContext.Provider>
  );
};