import clsx from 'clsx'
import { Link } from 'react-router-dom'

import Spinner from 'components/ui/Spinner'

const base = 'px-4 py-2 text-sm font-medium transition-colors rounded relative flex items-center'

const variants = {
  blue: base + ' text-white bg-blue-500 hover:bg-blue-600 border border-blue-600',
  gray: base + ' text-black bg-gray-200 hover:bg-gray-300 border border-gray-400',
  red: base + ' text-white bg-red-500 hover:bg-red-600 border border-red-600',
  white: base + ' text-black bg-white hover:bg-gray-50 border border-gray-200',
  green: base + ' text-white bg-green-600 hover:bg-green-700 border border-green-700',
  rounded: 'rounded-full px-2 py-2 hover:bg-black/5 transition-colors'
}

const Button = ({ children, ...props }) => {
  const variant = props.variant ?? 'blue'
  const onClick = props.onClick ?? undefined

  return (
    <button
      type="button"
      onClick={props.loading ? undefined : onClick}
      className={
        clsx(
          variants[variant],
          {
            'text-opacity-0': props.loading === true,
            '!px-1 !py-1 text-xs': props.small === true
          },
          (props.className || '')
        )
      }
    >
      {
        !props.loading ? children : '\u00a0'
      }
      {
        props.loading && (
          <div className="flex absolute inset-0 justify-center items-center">
            <Spinner size={24} color="rgb(255,255,255)" />
          </div>
        )
      }
    </button>
  )
}

// eslint-disable-next-line react/display-name 
const withLink = (Component) => (props) => {
  if (props.href) {
    return (
      <Link to={props.href}>
        <Component {...props} />
      </Link>
    )
  }

  return <Component {...props} />
}

export default withLink(Button)