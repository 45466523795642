import Card from 'components/ui/Card'

const EnqCancelled = () => {
  return (
    <Card>
      <div className="text-lg text-center">This enquiry has been cancelled.</div>
    </Card>
  )
}

export default EnqCancelled