import { useState, useRef, forwardRef, useEffect, useContext } from 'react'

import { activateLicense } from '../util/api'
import { OneSignalContext } from 'context/OneSignalContext'

const Login = () => {
  const { userId } = useContext(OneSignalContext)
  const [license, setLicense] = useState(Array(8).fill(''))
  const [errorMessage, setErrorMessage] = useState(null)
  const inputsRef = useRef([])

  useEffect(() => {
    if (license.every((el) => el === '')) {
      inputsRef.current[0].focus()
    }
  }, [])

  const handleChange = (ind, val) => {
    if (ind > license.length) return
    val = val.replace(/\D/g, '')

    if (val.length > 1) {
      val = val.split('')[val.length - 1]
    }

    let newLicense = [...license]
    newLicense[ind] = val
    setLicense(newLicense)

    if (val.length === 1 && inputsRef.current[ind + 1]) {
      inputsRef.current[ind + 1].focus()
    } else if (val.length === 0 && inputsRef.current[ind - 1]) {
      inputsRef.current[ind - 1].focus()
    }
  }

  const handleKeyDown = (ind, e) => {
    const input = inputsRef.current[ind - 1]

    if (e.target.value === '' && e.key === "Backspace" && input) {
      e.preventDefault()
      input.focus()
      const val = input.value
      input.value = ''
      input.value = val
    }
  }

  const handlePaste = (ind, e) => {
    e.preventDefault()
    const newVal = e.clipboardData.getData('text')
  }

  const handleActivate = async () => {
    const licenseJoined = license.join('')
    if (licenseJoined.length < license.length) return

    const res = await activateLicense(userId, licenseJoined)

    if (res) {
      if (res instanceof Error) {
        if (res.response.status === 500) {
          // bad guid
          localStorage.setItem('onesignalId', null)
          window.location.href = '/'
        } else if (res.response.status === 404) {
          setErrorMessage('Invalid license code')
        }
      } else {
        window.location.href = '/'
      }
    }
  }

  return (
    <div className="flex fixed inset-0 flex-col justify-center items-center bg-cpblue">
      <div className="text-2xl font-medium text-white">Enter license code</div>
      <div className="mt-4 text-xl text-red-500">{errorMessage}</div>
      <div className="my-8 space-x-4">
        {
          license.map((char, ind) => (
            <LicenseInput
              key={ind}
              ref={(el) => inputsRef.current[ind] = el}
              value={char}
              onChange={(e) => handleChange(ind, e.target.value)}
              onKeyDown={(e) => handleKeyDown(ind, e)}
              onPaste={(e) => /*handlePaste(ind, e)*/ { }}
            />
          ))
        }
      </div>
      <button
        onClick={handleActivate}
        className="px-28 py-6 mb-16 text-2xl font-bold text-white bg-gray-500 rounded-full border border-white shadow-xl transition-colors hover:bg-gray-600"
      >
        ACTIVATE
      </button>
      <img width="300px" src="/img/connectLogo.png" />
    </div>
  )
}

// eslint-disable-next-line react/display-name 
const LicenseInput = forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      className="px-6 py-8 w-20 text-3xl font-bold text-center rounded shadow-lg"
      type="text"
      {...props}
    />
  )
})

export default Login