import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Layout from 'routes/Layout'
import Home from 'routes/Home'
import Login from 'routes/Login'
import Settings from 'routes/Settings'
import Tracking from 'routes/Tracking'
import News from 'routes/News'
import Returns from 'routes/Returns'
import ApirError from 'routes/ApirError'


const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    //errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'enquiries',
        element: <Home />
      },
      {
        path: 'enquiries/:enqId',
        element: <Home />
      },
      {
        path: 'login',
        element: <Login />,
      }, {
        path: 'returns',
        element: <Returns />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'returns',
        element: <Returns />,
      },
      {
        path: 'tracking',
        element: <Tracking />,
      },
      {
        path: 'news',
        element: <News />,
      },
      {
        path: 'news/:newsId',
        element: <News />
      }
    ]
  },
  {
    path: 'error',
    element: <ApirError />
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)