import moment from 'moment'
import { Link } from 'react-router-dom'
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'

import Card from 'components/ui/Card'
import { getEnqColour } from 'util/helpers'
import RegPlate from 'components/EnquiryColumn/RegPlate'

const BulletinCard = ({ news }) => {

    const handleClick = () => {
        //setSelectedEnq(enq.EnquiryId)
    }

    return (
        <div className="">
            <Link to={`/news/${news.BulletinGuid}`} onClick={handleClick}>
                <Card hoverable>
                    <div className="flex items-center space-x-2">

                        <div className="flex-1">
                            <div className="text-xl font-bold uppercase">{news.Name}</div>
                            <div className="italic uppercase">{news.Notes}</div>
                            <div className="italic uppercase">RECEIVED: {moment(news.DateTime).format("DD/MM/YYYY")}</div>
                            <div className="italic uppercase">EXPIRES: {moment(news.Expiry).format("DD/MM/YYYY")}</div>
                        </div>
                    </div>

                    <div className="w-full my-3 h-[3px]" style={{}} />

                    <div className="flex justify-between items-center">


                    </div>
                </Card>
            </Link>
        </div>
    )
}

export default BulletinCard