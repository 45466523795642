import { useParams } from 'react-router-dom'

import NewsColumn from 'components/NewsColumn/NewsColumn'
import NewsDetailsColumn from 'components/NewsDetailsColumn/NewsDetailsColumn'
import '../util/modals'

const News = () => {
  const { newsId } = useParams()

  // if (isError) return <div className="col-span-4">Sorry - couldn't find any news for you.</div>


  return (
    <div className="grid grid-cols-12 gap-x-4 p-3 w-full max-w-full h-full">
      <div className="col-span-4">
        <NewsColumn />
      </div>
      {newsId && (
        <div className="col-span-8">
          <NewsDetailsColumn />
        </div>
      )}
    </div>
  )
}

export default News;
