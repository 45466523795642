import { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'

import { Button, Card } from 'components/gsys-ui'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const Dropdown = ({ label, values, onClick }) => {
  const containerRef = useRef(null)
  const { isOpen, setIsOpen } = useDropdown(containerRef)

  const handleSelect = (v) => () => {
    onClick(v)
    setIsOpen(false)
  }

  return (
    <div ref={containerRef} className="relative w-full">
      <Button onClick={() => setIsOpen(!isOpen)}>
        <div className="flex justify-between items-center w-full">
          <div>{label}</div>
          <ChevronDownIcon className="ml-2 w-4 h-4" />
        </div>
      </Button>

      <div className={clsx(
        'fixed z-50 pt-1 w-60 b-0',
        { 'hidden': !isOpen }
      )}>
        <Card noPad>
          <div className="flex flex-col">
            {
              values.map((v) => (
                <Button
                  key={v.id}
                  variant="white"
                  onClick={handleSelect(v)}
                  className="!border-none !rounded-none"
                >
                  {v.name}
                </Button>
              ))
            }
          </div>
        </Card>
      </div>
    </div>
  )
}

const useDropdown = (container) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const close = (e) => {
      if (container.current === e.target || container.current.contains(e.target)) return
      setIsOpen(false)
    }

    document.body.addEventListener('click', close)
    return () => document.body.removeEventListener('click', close)
  }, [])

  return {
    isOpen,
    setIsOpen
  }
}

export default Dropdown