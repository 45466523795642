import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Error, Row, Spacer, Footer, VSpacer } from 'components/ui/form/Form'
import TextInput from 'components/ui/form/TextInput'
import RegTextInput from 'components/ui/form/RegTextInput'
import { Button } from 'components/gsys-ui'
import RadioButton from 'components/ui/form/RadioButton'
import ImagePicker from 'components/ui/form/ImagePicker'
import PartsRadioButton from './PartsRadioButton'
import ConsumablesRadioButton from './ConsumablesRadioButton'
import { useFormikContext } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalContext'
import Dropdown from 'components/ui/Dropdown'
import { checkReg } from 'util/api'

const EnquiryForm = ({ isSubmitting, handleSubmit, error }) => {
  const modal = useModal()
  const [buttonLocked, setButtonLocked] = useState(false)
  const { setFieldValue, values } = useFormikContext()
  const { quickLaunchParts, setNotesExtra, apir, uinfo } = useContext(GlobalContext)
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [regError, setRegError] = useState(null)
  const [makeModel, setMakeModel] = useState(null)

  useEffect(() => {
    setNotesExtra('')
  }, [])

  useEffect(() => {
    if (deliveryTime === 'sameday') {
      setNotesExtra('[SAME DAY] ')
    } else if (deliveryTime === 'nextday') {
      setNotesExtra('[NEXT DAY] ')
    } else {
      setNotesExtra('')
    }
  }, [deliveryTime])

  // Reset makeModel when switching to Consumables
  useEffect(() => {
    if (values.Consumables === 'Consumables') {
      setMakeModel(null);
    }
  }, [values.Consumables]);

  const showMakeModelPrompt = () => {
    NiceModal.show('makeModelModal', {
      onSubmit: ({ make, model }) => {
        setMakeModel({ make, model });
        // Append make/model to notes
        const makeModelText = `\n\n(${make}, ${model})`;
        setFieldValue('Notes', values.Notes ? values.Notes + makeModelText : makeModelText);
      }
    });
  };

  const handlePartListModal = () => {
    NiceModal.show('partListModal', {
      submit: (partList) => {
        setFieldValue('Parts', partList)
      }
    })
  }

  const quickLaunchDropdown = quickLaunchParts.map((p, ind) => ({ id: ind, name: p }))

  const handleSubmitWrapper = async (e) => {
    e.preventDefault();

    // Don't require make/model for consumables
    if (values.Consumables !== 'Consumables') {
      // Show modal if make/model not entered
      if (!makeModel) {
        showMakeModelPrompt();
        return;
      }
    }

    handleSubmit(e);
  };

  return (
    <>
      {error && <Error>{error}</Error>}
      <Row>
        <Button
          variant={deliveryTime === 'sameday' ? 'green' : 'white'}
          onClick={() => (deliveryTime === 'sameday' ? setDeliveryTime(null) : setDeliveryTime('sameday'))}
        >
          Same day
        </Button>
        <Button
          variant={deliveryTime === 'nextday' ? 'green' : 'white'}
          onClick={() => (deliveryTime === 'nextday' ? setDeliveryTime(null) : setDeliveryTime('nextday'))}
        >
          Next day
        </Button>
      </Row>
      <Spacer />
      <Row>
        <RegTextInput
          name="Reg"
          placeholder="Enter reg"
          size={2}
          disabled={values.Consumables === 'Consumables'}
          onBlur={() => {
            if (values.Reg && values.Reg.length >= 3 && values.Consumables !== 'Consumables' && !makeModel) {
              showMakeModelPrompt();
            }
          }}
        />
        <div className="flex justify-between items-center w-full">
          <PartsRadioButton name="Consumables" label="Parts" value="Parts" />
          <ConsumablesRadioButton name="Consumables" label="Consumables" value="Consumables" />
        </div>
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-end w-full">
          <div className="flex-1">
            <TextInput name="Parts" label="Part details" />
          </div>
        </div>
      </Row>
      <Row>
        <div className="flex items-end mt-2 w-full">
          <div className="mr-2">
            <Dropdown
              values={quickLaunchDropdown}
              label="Use part shortcut"
              onClick={(v) => setFieldValue('Parts', v.name)}
            />
          </div>
          <div>
            <Button onClick={handlePartListModal}>Use part list</Button>
          </div>
        </div>
      </Row>
      <Spacer />
      <Row>
        <TextInput name="Notes" label="Notes" />
      </Row>
      <Spacer />
      <Row>
        <div className="flex items-center w-full">
          <div className="flex flex-1 justify-between items-center w-full">
            <RadioButton name="Status" label="Quote" value="Waiting" />
            <RadioButton name="Status" label="Order" value="Order" />
          </div>
          <div className="flex-1 w-full">
            <ImagePicker name="Image" />
          </div>
        </div>
      </Row>
      <Spacer />
      <Footer>
        <Button variant="white" onClick={modal.remove}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmitWrapper}
          loading={isSubmitting}
          disabled={values.Consumables === 'Consumables' ? false : buttonLocked}
        >
          Submit
        </Button>
      </Footer>
    </>
  )
}

export default EnquiryForm