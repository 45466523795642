import moment from 'moment'
import { Link } from 'react-router-dom'
import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'

import Card from 'components/ui/Card'
import { getEnqColour } from 'util/helpers'
import RegPlate from 'components/EnquiryColumn/RegPlate'

const EnquiryCard = ({ enq, eta }) => {
  const statusColor = getEnqColour(enq.Status)

  const handleClick = () => {
    //setSelectedEnq(enq.EnquiryId)
  }

  return (
    <div className="">
      <Link to={`/enquiries/${enq.EnquiryId}`} onClick={handleClick}>
        <Card hoverable>
          <div className="flex items-center space-x-2">
            <RegPlate size={1.2}>{enq.Reg}</RegPlate>
            <div className="flex-1">
              <div className="text-xl font-bold uppercase">{enq.Parts}</div>
              <div className="italic uppercase">{enq.Notes}</div>
            </div>
          </div>

          <div className="w-full my-3 h-[3px]" style={{ backgroundColor: statusColor }} />

          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-1 text-xl">
              {
                enq.GarageUnread ? (
                  <EnvelopeOpenIcon className="w-6 h-6" />
                ) : (
                  <EnvelopeIcon className="w-6 h-6" />
                )
              }
              <div className="font-thin tracking-wide">
                {enq.MessageCount}/({enq.GarageUnread})
              </div>
            </div>

            <div className="text-lg font-medium uppercase">
              {
                enq.Status === 'Ordered' && eta ? (
                  <div>{enq.Status} | ETA: {moment(eta).format('DD/MM/YY HH:mm')}</div>
                ) : (
                  <div>{enq.Status}</div>
                )
              }
            </div>

            <div className="font-thin tracking-wide">
              {moment(enq.DatetimeCreated).format('DD/MM')}
            </div>
          </div>
        </Card>
      </Link>
    </div>
  )
}

export default EnquiryCard