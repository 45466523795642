import { useState, useEffect, useContext } from 'react'
import { MagnifyingGlassIcon, CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline'

import * as api from 'util/api'
import InputWithButton from 'components/ui/InputWithButton'
import { Button } from 'components/gsys-ui'
import BulletinCard from 'components/NewsColumn/BulletinCard'
import usePaginated from 'hooks/usePaginated'
import NiceModal from '@ebay/nice-modal-react'
import { EnquiriesContext } from 'context/EnquiriesContext'
import { useBulletins } from 'util/queries'
import moment from 'moment'
import Spinner from '../ui/Spinner'

const NewsColumn = () => {
    const { isLoading, isError, data: news, error } = useBulletins()

    if (isError) return <div className="col-span-4">Sorry - Couldn't find any news for you 😔</div>

    //console.log(JSON.stringify(news))

    return (
        <div className="flex overflow-hidden flex-col col-span-4 h-full">
            <div className="flex mb-5 space-x-2 flex-0">
                YOUR BULLETINS
            </div>
            {
                isLoading ? (
                    <div className="inline-block mx-auto"><Spinner /></div>
                ) : (
                    <div className="overflow-y-scroll flex-1 space-y-2">
                        {
                            news.data.map((news, index) => {
                                //const eta = enqEtaCheck && enqEtaCheck[enq.EnquiryId] ? enqEtaCheck[enq.EnquiryId].Eta : undefined
                                return <BulletinCard key={index} news={news} />
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default NewsColumn