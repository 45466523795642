import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { GlobalProvider, GlobalContext } from 'context/GlobalContext'
import { OneSignalContext } from 'context/OneSignalContext'

import * as api from './api'


export const useEnquiries = () => useQuery({
  queryKey: ['enqs'],
  queryFn: () => api.getEnquiries()
})

export const useEnquiryResponse = (enqId) => useQuery({
  queryKey: ['enqRes', enqId],
  queryFn: () => api.getEnquiryResponse(enqId)
})

export const useMessages = (enqId) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['msgs', enqId],
    queryFn: () => api.getMessages(enqId, apir)
  });
}

export const useBulletins = () => {
  const { apir } = useContext(GlobalContext);
  const { userId } = useContext(OneSignalContext)
  return useQuery({
    queryKey: ['news'],
    queryFn: () => api.getUserBulletins(userId, apir)
  });
}

export const useBulletinsId = (bId) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['bulletin', bId],
    queryFn: () => api.getBulletinById(bId, apir)
  });
}

export const useGetReturnsPartList = (acct) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['returns', acct],
    queryFn: () => api.getReturnPartsList(acct, apir)
  });
}

export const useInTransit = (acct) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['inTransit', acct],
    queryFn: () => api.getInTransit(acct, apir)
  });
}

export const useDropInTransit = (dropId) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['dropInTransit', dropId],
    queryFn: () => api.getDropInTransit(dropId, apir)
  });
}

export const useQuoteDetails = (documentId) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['quoteDetails', documentId],
    queryFn: () => api.getQuoDetails(documentId, apir),
    enabled: !!documentId
  });
}

export const usePartStocks = (partNumbers) => {
  const { apir } = useContext(GlobalContext);
  return useQuery({
    queryKey: ['partStocks', partNumbers],
    queryFn: () => api.getPartStocks(partNumbers, apir),
    enabled: !!partNumbers && partNumbers.length > 0
  });
}