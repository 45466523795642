import { useContext, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { CalendarDaysIcon, MinusIcon } from '@heroicons/react/24/outline'
import DatePicker from 'react-datepicker'


import { Row } from 'components/ui/form/Form'
import ModalContainer from 'components/ui/ModalContainer'

import 'react-datepicker/dist/react-datepicker.css'
import { Button, Card } from 'components/gsys-ui'

const DateFilterModal = NiceModal.create(({ startDate, endDate, setStartDate, setEndDate }) => {
  const modal = useModal()
  const [formStart, setFormStart] = useState(startDate)
  const [formEnd, setFormEnd] = useState(endDate)

  const handleSave = () => {
    setStartDate(formStart)
    setEndDate(formEnd)
    modal.remove()
  }

  const handleClear = () => {
    setStartDate(null)
    setEndDate(null)
    modal.remove()
  }

  return (
    <ModalContainer>
      <div className="w-[400px]">
        <Card noPad>
          <Card.Title Icon={CalendarDaysIcon}>Filter by date</Card.Title>
          <Card.Body>
            <Row>
              <DatePicker
                selected={formStart}
                onChange={setFormStart}
                className="p-2 w-full text-sm font-normal bg-white rounded border border-gray-300 transition-colors focus:border-blue-500 focus:outline-none"
                dateFormat="dd/MM/yyyy"
                placeholderText="Start date"
              />
              <div className="flex items-center">
                <MinusIcon className="w-6 h-6 text-gray-400" />
              </div>
              <DatePicker
                selected={formEnd}
                onChange={setFormEnd}
                className="p-2 w-full text-sm font-normal bg-white rounded border border-gray-300 transition-colors focus:border-blue-500 focus:outline-none"
                dateFormat="dd/MM/yyyy"
                placeholderText="End date"
              />
            </Row>
            <div className="flex justify-end mt-3 space-x-2">
              <Button variant="white" onClick={handleClear}>Clear</Button>
              <Button onClick={handleSave}>Save</Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </ModalContainer>
  )
})

export default DateFilterModal